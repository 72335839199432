import React from "react";
import { Grid } from "@material-ui/core";
import logo from "../../static/images/logo_white.png";
// import { withStyles } from "@material-ui/core/styles";
import styles from "./Footer.module.scss";

// const CssTextField = withStyles({
//   root: {
//     "& .MuiInputBase-input": {
//       borderColor: "#f5f5f5",
//       color: "#f5f5f5",
//     },
//     "& .MuiFormLabel-root": {
//       color: "#f5f5f5",
//     },
//     "& label.Mui-focused": {
//       color: "#f50057",
//     },
//     "& .MuiInputBase-root": {
//       "& fieldset": {
//         borderColor: "#f5f5f5",
//       },
//       "&:hover fieldset": {
//         borderColor: "#f50057",
//       },
//     },
//     "& .MuiOutlinedInput-input": {
//       paddingRight: 0,
//     },
//   },
// })(TextField);

export default class extends React.Component {
  render() {
    return (
      <Grid item xs={12} xl={3} sm={6} md={4} lg={3}>
        <img src={logo} className={styles.boardLogo} alt="触梦科技" />
        {/* <Box>
                    <Box>
                        <CssTextField size="small" InputLabelProps={{
                            shrink: true,
                        }} variant="outlined" color="secondary" id="standard-basic" label="点击输入手机号" />
                    </Box>
                    <Box>
                        <Button size="large" className={styles.boardButton} variant={'outlined'} >免费咨询</Button>
                    </Box>
                </Box> */}
      </Grid>
    );
  }
}
