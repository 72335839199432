import { Box } from "@material-ui/core";
import React from "react";
import styles from "./styles.module.scss";

import img from "../../../static/images/product/product_01.jpg";

const ProductCard = (props) => {
  const { number = '01', title = "游戏研发", cover = img } = props;

  return (
    <Box className={styles.productCard}>
      <span className={styles.No}>{number}</span>
      <h2>
        <span>{title}</span>
      </h2>
      <img className={styles.cover} alt={"游戏研发"} src={cover}></img>
    </Box>
  );
};

export default ProductCard;
