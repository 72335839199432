import React from "react";
import Layout from "../../components/layout/Layout";
import { Box, Grid, Typography, Container } from "@material-ui/core";
import styles from "./index.module.scss";
import WaveBorder from "../../components/effect/WaveBorder";
import bannerVideo from "../../static/videos/video.mp4";
import logo from "../../static/images/logo_white.png";
import Fade from "@material-ui/core/Fade";
import BrushIcon from "@material-ui/icons/Brush";
import MovieFilterIcon from "@material-ui/icons/MovieFilter";
import FastfoodIcon from "@material-ui/icons/Fastfood";
import ProductCard from "./components/ProductCard";
import productCover_01 from "../../static/images/product/product_01.jpg";
import productCover_02 from "../../static/images/product/product_02.jpg";
import productCover_03 from "../../static/images/product/product_03.jpg";

const partners = [];
for (let i = 1; i <= 8; i++) {
  partners.push(require("../../static/images/partners/partner_0" + i + ".png"));
}

export default class Home extends React.Component {
  constructor(props) {
    super(props);
    let ua = navigator.userAgent.toLowerCase();
    this.state = {
      isWx: !!ua.match(/MicroMessenger/i),
      coverState: "block",
    };
  }

  render() {
    return (
      <Layout>
        <Box
          component="main"
          className={styles.main}
          style={{ overflow: "hidden" }}
        >
          <Box className={styles.banner}>
            {this.state.isWx ? null : (
              <video
                src={bannerVideo}
                autoPlay="autoplay"
                muted
                loop
                webkit-playsinline="true"
                playsInline={true}
                x5-playsinline="true"
                className={styles.video}
                onPlay={() => {
                  this.setState({coverState: 'none'});
                }}
              ></video>
            )}
            <div
              className={styles.cover}
              style={{ display: this.state.coverState }}
            ></div>
            <Fade in={true} timeout={1500}>
              <Box className={styles.mask}>
                <img src={logo} className={styles.logo} alt="触梦科技" />
                <Typography variant="h6" style={{ padding: "0 10px" }}>
                  专注于互联网应用开发，追求创新、敏捷和高品质。
                </Typography>
              </Box>
            </Fade>
            <WaveBorder />
          </Box>
          <Box className={`${styles.whiteBox} ${styles.product}`}>
            <h1>产品与服务</h1>
            <Grid container justify="center" alignItems="center" spacing={10}>
              <Grid item>
                <ProductCard
                  number={"01"}
                  title={"游戏研发"}
                  cover={productCover_01}
                ></ProductCard>
              </Grid>
              <Grid item>
                <ProductCard
                  number={"02"}
                  title={"数字会展"}
                  cover={productCover_02}
                ></ProductCard>
              </Grid>
              <Grid item>
                <ProductCard
                  number={"03"}
                  title={"软件定制"}
                  cover={productCover_03}
                ></ProductCard>
              </Grid>
            </Grid>
          </Box>
          <Box className={`${styles.blackBox} ${styles.culture}`}>
            <h1>
              <span>企业文化</span>
            </h1>
            <Grid
              container
              justify="center"
              alignItems="flex-start"
              spacing={10}
            >
              <Grid item className={styles.container}>
                <Box>
                  <BrushIcon className={styles.icon} />
                </Box>
                <Box>
                  <Typography align={"left"}>
                    <span className={styles.pink}>自强</span>
                    <br />
                    “天行健，君子以自强不息”&nbsp;
                  </Typography>
                </Box>
              </Grid>
              <Grid item className={styles.container}>
                <Box>
                  <MovieFilterIcon className={styles.icon} />
                </Box>
                <Box>
                  <Typography align={"left"}>
                    <span className={styles.pink}>不屈</span>
                    <br />
                    不屈是一名英雄最重要的品质。
                  </Typography>
                </Box>
              </Grid>
              <Grid item className={styles.container}>
                <Box>
                  <FastfoodIcon className={styles.icon} />
                </Box>
                <Box>
                  <Typography align={"left"}>
                    <span className={styles.pink}>科技改变生活</span>
                    <br />
                    我们坚信科技拥有实现每个人
                    <br />
                    梦想的力量。这也是我们不断 <br />
                    追求的目标。
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Box className={`${styles.whiteBox} ${styles.brand}`}>
            <h1>服务品牌</h1>
            <Container fixed style={{ maxWidth: "1200px" }}>
              <Grid
                container
                justify="space-evenly"
                alignItems="center"
                spacing={1}
              >
                <Grid
                  item
                  sm={6}
                  md={3}
                  className={styles.logo}
                >
                  <img alt="Our Partners" src={partners[0]} />
                </Grid>
                <Grid
                  item
                  sm={6}
                  md={3}
                  className={styles.logo}
                >
                  <img alt="Our Partners" src={partners[1]} />
                </Grid>
                <Grid
                  item
                  sm={6}
                  md={3}
                  className={styles.logo}
                >
                  <img alt="Our Partners" src={partners[2]} />
                </Grid>
                <Grid
                  item
                  sm={6}
                  md={3}
                  className={styles.logo}
                >
                  <img alt="Our Partners" src={partners[3]} />
                </Grid>
                <Grid
                  item
                  sm={6}
                  md={3}
                  className={styles.logo}
                >
                  <img alt="Our Partners" src={partners[4]} />
                </Grid>
                <Grid
                  item
                  sm={6}
                  md={3}
                  className={styles.logo}
                >
                  <img alt="Our Partners" src={partners[5]} />
                </Grid>
                <Grid
                  item
                  sm={6}
                  md={3}
                  className={styles.logo}
                >
                  <img alt="Our Partners" src={partners[6]} />
                </Grid>
                <Grid
                  item
                  sm={6}
                  md={3}
                  className={styles.logo}
                >
                  <img alt="Our Partners" src={partners[7]} />
                </Grid>
              </Grid>
            </Container>
          </Box>
        </Box>
      </Layout>
    );
  }
}
