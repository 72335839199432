import React from "react";
import { Container, Grid, Box, Typography } from "@material-ui/core";
import PhoneIcon from "@material-ui/icons/Phone";
import MailIcon from "@material-ui/icons/Mail";
import { PinDrop } from "@material-ui/icons";
import styles from "./Footer.module.scss";
import Board from "./Board";
import Section from "./Section";

const product = [
  {
    url: "https://www.lookingai.com",
    name: "看看AI",
  },
  {
    url: "https://dpm.not2.cn",
    name: "触梦大屏幕",
  },
];

const infos = [
  {
    icon: <PhoneIcon fontSize="small" />,
    name: "1 388 328 9823",
  },
  {
    icon: <MailIcon fontSize="small" />,
    name: "ichumeng@163.com",
  },
  {
    icon: <PinDrop fontSize="small" />,
    name: "重庆市南岸区亚太路玖玺国际6栋14-9",
  },
];

const labs = [
  // {
  //   url: "https://dong.not2.cn",
  //   name: "咚咚",
  // },
  // {
  //   url: "https://www.lightaboy.com",
  //   name: "Lightaboy",
  // },
];

// const innerStyles = (theme) => ({
//     footerInner: {
//         backgroundColor: theme.palette.common.darkBlack,
//         paddingTop: theme.spacing(8),
//         paddingLeft: theme.spacing(2),
//         paddingRight: theme.spacing(2),
//         paddingBottom: theme.spacing(6),
//         [theme.breakpoints.up("sm")]: {
//           paddingTop: theme.spacing(10),
//           paddingLeft: theme.spacing(16),
//           paddingRight: theme.spacing(16),
//           paddingBottom: theme.spacing(10)
//         },
//         [theme.breakpoints.up("md")]: {
//           paddingTop: theme.spacing(10),
//           paddingLeft: theme.spacing(10),
//           paddingRight: theme.spacing(10),
//           paddingBottom: theme.spacing(10)
//         }
//       },
// })

export default class Footer extends React.Component {
  render() {
    return (
      <footer className={styles.footer}>
        <Container className={styles.container}>
          <Grid container justify={"space-between"} spacing={3}>
            <Board />
            <Section title="产品" list={product} />
            <Section title="实验室" list={labs} />
            <Section title="关于我们" list={infos} />
            {/* <Grid item xs={12} xl={4} sm={6} md={4} lg={4}>
                            <Typography variant="subtitle1" className={styles.rowName}> 联系方式</Typography>
                            {
                                infos.map((info, index) => (
                                    <Box display="flex" mb={1} key={index}>
                                        <Box display="flex" flexDirection="column" justifyContent="center">
                                            <Typography variant="subtitle2" className={styles.textWhite}>
                                                {info.description}
                                            </Typography>
                                        </Box>
                                    </Box>
                                ))
                            }
                        </Grid> */}
          </Grid>
        </Container>
        <Box className={styles.company}>
          <Typography
            variant={"caption"}
            style={{ color: "hsla(0, 0%, 100%, 0.5)" }}
          >
            Copyright© 2016 - 2020 触梦科技. All Rights Reserved.
          </Typography>
          <a
            className={styles.link}
            href="http://beian.miit.gov.cn/publish/query/indexFirst.action"
          >
            <Typography variant={"caption"}>
              &nbsp;&nbsp;&nbsp;&nbsp;渝ICP备16002738号-1
            </Typography>
          </a>
        </Box>
      </footer>
    );
  }
}
