import React from 'react';
import Header from '../header/Header.jsx';
import Footer from '../footer/Footer.jsx';

/**
 * 布局组件
 *
 * @extends {React.Component}
 */
export default class Layout extends React.Component {

    /**
     * 渲染方法
     * @return {JSX} - 组件。
     */
    render() {
        return (
            <>
                <Header />
                {this.props.children}
                <Footer />
            </>
        );
    }
}