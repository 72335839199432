import React from "react";
import { AppBar, Toolbar, Button } from "@material-ui/core";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import NewReleasesOutlinedIcon from "@material-ui/icons/NewReleasesOutlined";
// import MenuIcon from '@material-ui/icons/Menu';
import styles from "./Header.module.scss";
import logo from "../../static/images/logo.png";

export default function Header() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorElLab, setAnchorElLab] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseLabMenu = () => {
    setAnchorElLab(null);
  };

  return (
    <React.Fragment>
      <AppBar className={styles.header}>
        <Toolbar className={styles.toolbar}>
          <img src={logo} alt="触梦科技" className={styles.logo} />
          <div style={{ flexGrow: 1 }}></div>
          {/* <IconButton edge="start" color="inherit" aria-label="menu">

          <Typography variant="h6">LOGO</Typography>
        </IconButton> */}
          <Button
            aria-controls="product-menu"
            aria-haspopup="true"
            color="inherit"
            onClick={handleClick}
          >
            产品
          </Button>
          <Menu
            id="product-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem onClick={handleClose}>
              <a className={styles.link} href="https://www.lookingai.com/image/meme" rel="noopener noreferrer" target="_blank">
                <NewReleasesOutlinedIcon
                  fontSize="small"
                  style={{ color: "#f50057" }}
                ></NewReleasesOutlinedIcon>
                &nbsp;表情包修复
              </a>
            </MenuItem>
            <MenuItem onClick={handleClose}>
              <a className={styles.link} href="https://www.lookingai.com/image/sr" rel="noopener noreferrer" target="_blank">
                <NewReleasesOutlinedIcon
                  fontSize="small"
                  style={{ color: "#f50057" }}
                ></NewReleasesOutlinedIcon>
                &nbsp;照片修复
              </a>
            </MenuItem>
            <MenuItem onClick={handleClose}>
              <a className={styles.link} href="https://www.lookingai.com/image/anime" rel="noopener noreferrer" target="_blank">
                <NewReleasesOutlinedIcon
                  fontSize="small"
                  style={{ color: "#f50057" }}
                ></NewReleasesOutlinedIcon>
                &nbsp;头像动漫化
              </a>
            </MenuItem>
          </Menu>
          <Button
            aria-controls="lab-menu"
            aria-haspopup="true"
            color="inherit"
            onClick={(event) => {
              // setAnchorElLab(event.currentTarget)
            }}
          >
            实验室
          </Button>
          <Menu
            id="lab-menu"
            anchorEl={anchorElLab}
            keepMounted
            open={Boolean(anchorElLab)}
            onClose={handleCloseLabMenu}
          ></Menu>
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
}
