import React from "react";
import { Grid, Box, Typography, IconButton } from "@material-ui/core";
import styles from "./Footer.module.scss";

export default (props) => {
  const { list } = props;
  return (
    <Grid item xs={12} xl={3} sm={6} md={2} lg={3}>
      <Typography variant="subtitle1" className={styles.rowName}>
        {props.title}
      </Typography>
      {list && list.length > 0
        ? list.map((item, index) => (
            <Box display="flex" mb={1} key={index}>
              {item.icon && (
                <Box mr={1}>
                  <IconButton disabled className={styles.infoIcon} size="small">
                    {item.icon}
                  </IconButton>
                </Box>
              )}
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
              >
                <a href={item.url} atl={item.name} className={styles.link}>
                  <Typography variant="subtitle2" className={styles.textWhite}>
                    {item.name}
                  </Typography>
                </a>
              </Box>
            </Box>
          ))
        : (<span>暂无</span>)}
    </Grid>
  );
};
